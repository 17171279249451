<template>
    <div>
        <v-app style="min-height: auto" id="prebuild-convs">
            <v-card>
                <v-card-title>
                    Alege un departament pentru a vedea conversatii
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mb-2" v-b-modal.new-prebuild-conv>
                        Adauga
                        <NewSavedReply :departments="departments" />
                    </v-btn>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="savedReplies"
                    :expanded.sync="expanded"
                    item-key="name"
                    :search="search"
                    show-expand
                    class="elevation-1"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-select
                                :items="departments"
                                label="Alege departament"
                                @change="
                                    getSavedReplies();
                                    routerDep(department);
                                "
                                v-model="department"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Cauta"
                                single-line
                                hide-details
                            ></v-text-field>

                            <EditSavedReply v-model="editedItem" />

                            <v-dialog v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-h5"
                                        >Sunteti sigur ca doriti sa stergeti aceasta
                                        conversatie predefinita?</v-card-title
                                    >
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeDelete"
                                            >Cancel</v-btn
                                        >
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="deleteItemConfirm"
                                            >OK</v-btn
                                        >
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="p-2">
                            <p v-html="item.text"></p>
                        </td>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                    </template>

                    <template v-slot:no-data>
                        <div>Nu exista mail-uri</div>
                    </template>
                </v-data-table>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import NewSavedReply from "./NewSavedReply.vue";
import EditSavedReply from "./EditSavedReply.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    data() {
        return {
            department: "",
            search: "",
            dialogDelete: false,
            headers: [
                {
                    text: "Nume conversatie predefinita",
                    align: "start",
                    sortable: false,
                    value: "name",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Editare",
                    value: "actions",
                    sortable: false,
                    class: "h6 grey lighten-2",
                    width: "5%",
                },
                { text: "", value: "data-table-expand", class: "h6 grey lighten-2" },
            ],
            expanded: [],
            dialog: false,
            editedItem: {
                id: null,
                name: "",
                text: "",
                mailbox_id: null,
            },
            deletedItem: {
                id: null,
                mailbox_id: null,
            },
            editedIndex: -1,
        };
    },
    components: { NewSavedReply, EditSavedReply },
    computed: {
        departments() {
            const departments = this.$store.getters["departments/departments"];
            let eachDepartmentOptions = [
                { value: null, text: "Alege departament", disabled: true },
            ];
            let onlyDeps = null;
            departments.forEach((dep) => {
                eachDepartmentOptions.push({ value: dep.id, text: dep.name });
            });
            return eachDepartmentOptions;
        },
        savedReplies() {
            return this.$store.getters["mails/savedReplies"];
        },
    },
    methods: {
        getSavedReplies() {
            let payload = {
                id: this.department ? this.department : 1,
            };
            this.$store.dispatch("mails/getSavedReplies", payload);
        },
        routerDep(department = 1) {
            this.$router.push({
                name: "SavedReplies",
                params: { department: department },
            });
        },
        editItem(item) {
            this.editedIndex = this.savedReplies.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.$bvModal.show("edit-prebuild-conv");
        },
        deleteItem(item) {
            this.deletedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        deleteItemConfirm() {
            this.closeDelete();
            this.$store.dispatch("mails/deleteSavedReply", {
                saved_reply_id: this.deletedItem.id,
                mailbox_id: this.deletedItem.mailbox_id,
            });
        },
    },

    created() {
        this.$store.dispatch("departments/getDepartments").then(() => {
            if (this.$router.history.current.params.department) {
                this.department = +this.$router.history.current.params.department;
            }
            // else {
            //     this.department = 1;
            // }
            this.getSavedReplies();
        });
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Mail Pefoc.ro - Lista conversatii predefinte" },
        ]);
        if (this.savedReplies.length) {
            this.department = this.savedReplies[0].mailbox_id;
        }
    },
};
</script>

<style>
#prebuild-convs tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
</style>
