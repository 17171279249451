<template>
  <b-modal
    id="edit-prebuild-conv"
    title="Editeaza conversatie predefinta"
    size="lg"
    hide-footer
  >
    <b-form @submit="onSubmit">
      <b-form-group id="input-group-1">
        <label style="font-weight: bold" for="pName">Nume conversatie predefinta:</label>
        <b-form-input
          id="input-1"
          class="w-50"
          v-model="value.name"
          type="text"
          required
          placeholder="Introdu nume"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <!--begin::Message-->
        <label style="font-weight: bold">Continut conversatie predefinita</label>
        <vue-editor v-model="value.text" :editor-toolbar="customToolbar" />

        <!--end::Message-->
      </b-form-group>

      <b-button variant="danger" class="mr-2" @click="close">Inchide</b-button>
      <b-button type="submit" variant="primary">Salveaza</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      show: true,
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        ["link"],
        ["clean"],
      ],
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: { VueEditor },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      let payload = {
        saved_reply_id: this.value.id,
        name: this.value.name,
        text: this.value.text,
        mailbox_id: this.value.mailbox_id,
      };
      this.$store.dispatch("mails/updateSavedReply", payload).then(() => {
        this.$bvModal.hide("edit-prebuild-conv");
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      });
    },
    close() {
      this.$bvModal.hide("edit-prebuild-conv");
    },
  },
};
</script>
