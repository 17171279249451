<template>
  <b-modal
    id="new-prebuild-conv"
    title="Conversatie predefinita noua"
    size="lg"
    hide-footer
  >
    <b-form @submit="onSubmit" @reset="onReset">
      <b-form-group id="input-group-1">
        <label style="font-weight: bold" for="pName">Nume conversatie predefinta:</label>
        <b-form-input
          id="input-1"
          class="w-50"
          v-model="form.name"
          type="text"
          required
          placeholder="Introdu nume"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2">
        <label style="font-weight: bold">Departamentul unde va fi conversatia:</label
        ><br />
        <b-form-select
          v-model="form.selected"
          id="input-3"
          class="w-50 d-inline"
          required
          placeholder="Departament"
          :options="departments"
        ></b-form-select>
      </b-form-group>

      <b-form-group>
        <!--begin::Message-->
        <label style="font-weight: bold">Continut conversatie predefinita</label>
        <vue-editor v-model="form.text" :editor-toolbar="customToolbar" />

        <!--end::Message-->
      </b-form-group>

      <b-button type="reset" variant="danger" class="mr-2">Reset</b-button>
      <b-button type="submit" variant="primary">Adauga</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      form: {
        name: "",
        selected: null,
        text: "",
      },
      show: true,
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        ["link"],
        ["clean"],
      ],
    };
  },
  props: ["departments"],
  components: { VueEditor },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      let payload = {
        name: this.form.name,
        text: this.form.text,
        mailbox_id: this.form.selected,
      };
      this.$store.dispatch("mails/createSavedReply", payload).then(() => {
        this.$bvModal.hide("new-prebuild-conv");
        this.onReset();
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      });
    },
    onReset() {
      // Reset our form values
      this.form.name = "";
      this.form.text = "";
      this.form.selected = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
